import React, { useContext, useState, useEffect } from 'react';
import { Popup } from 'react-map-gl';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import TopTrafficSource from './../../Chart/top-traffic-source';
import { DataContext } from './../../../context/DataContext';
import { GeneralContext } from './../../../context/GeneralContext';

const tooltip = (
    <Tooltip id="tooltip">
        <div>This is a simplified calculation and assumes complete combustion of the oil, which might not be the case in real-world scenarios. The actual amount of CO₂ emitted can vary depending on various factors such as the combustion efficiency, impurities in the oil, and combustion conditions. Plus no count made for additional emissions caused by flaring or processing of oil in the extraction and  refinement processes.</div>
    </Tooltip>
);

const BarrelMarkerPopup = ({ marker, markerDetails, setMarkerDetails }) => {

    const navigate = useNavigate();
    const { setActiveRoute } = useContext(DataContext);
    const { axios } = useContext(GeneralContext);

    const [documents, setDocuments] = useState([])
    const { lat, lon, id, icon, stream } = marker;

    const getDocuments = async (key) => {
        const { error, data: { data } } = await axios.get(`/${stream}-document-metadata/transactions-by-key/${key}?page=1&items=10`);
        setDocuments(data);
    }

    useEffect(() => {
        const fetchOilFieldDocuments = async () => {
            await getDocuments(id);
        }
        fetchOilFieldDocuments().catch(console.error);
    }, [])

    const rn = (max) => (Math.floor(Math.random() * max));

    useEffect(() => {
    }, [documents])

    const topTrafficSource = [
        { name: 'Tokens', value: rn(2560) },
        { name: 'CO2', value: rn(4150) },
    ];

    return (markerDetails && markerDetails.id === id) &&

        <Popup className='w-50' id={id} key={id} latitude={lat} longitude={lon} onClose={() => setMarkerDetails(null)} closeOnClick={false}  >
            <div className='row pl-2 pr-1'>
                <div className='col-4'>
                    <div className='row pr-2'>
                        <div className='col-12'>
                            <strong>Barrels Data </strong>
                            <div className='d-flex flex-row'>
                                <div className='p-0'>Details : </div>
                                <Nav.Link className="text-info p-0 ms-1"
                                    onClick={() => {
                                        navigate(`/${icon}-data`, { state: { [`${icon}Id`]: id } });
                                        setActiveRoute(`${icon}`);
                                    }}>
                                    {id}
                                </Nav.Link>
                            </div>
                            <hr />
                            <strong>Quality </strong>
                            <div>API Gravity (degrees) : <span className="text-success">40.4</span></div>
                            <div>Sulphur content : <span className="text-success">0.79%</span></div>
                            <div>Carbon content : <span className="text-info">91.6%</span></div>
                            <hr />

                            <div><strong>Tokens : </strong><span className="text-success">3,620,915</span></div>
                            <hr />
                            <strong>Documents </strong>
                            <div>
                                {
                                    documents.map(document => {
                                        const { data: { json: { name, hash, extension } } } = document;
                                        return (
                                            <Nav.Link
                                                key={hash}
                                                className="text-info p-0 ms-1"
                                                onClick={() => { console.log(hash) }}>
                                                {name}
                                            </Nav.Link>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-8'>
                    <div className='row'>
                        <div className='border-left-custom pl-2 pr-2 col-12'>
                            <TopTrafficSource description={"1,102 Tokens / 14,411 CO2"} title={"Tokens / CO2 2023"} data={topTrafficSource} className="chart-fluid-2" />
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
}

export default BarrelMarkerPopup