import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import WidgetCard from './../../components/Card/widget-card';

import './theme.css';

const UserDashboard = () => {

    const { state } = useLocation();

    const { axios } = useContext(GeneralContext);

    const [userData, setUserData] = useState(null);
    const [userId] = useState(state ? state.userId : 'user');

    const columns = [
        {
            dataField: 'data.json.id',
            text: 'Username',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'data.json.address',
            text: 'Address',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'timereceived',
            text: 'Created At',
            formatter: (data, index) => {
                return moment(data * 1000).format('YYYY-MM-DD hh:mm:ss');
            },
            sort: true
        },
        {
            dataField: 'data.json.role',
            text: 'Role',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        }
    ];

    const getUsers = async () => {
        const route = (userId !== undefined && userId !== 'user') ? `/user/transactions-by-key/${userId}?page=1&items=1000` : `/user?page=1&items=1000`;
        const { data: { data } } = await axios.get(route);
        setUserData(data)
    }

    useEffect(() => {
        const fetchUsers = async () => {
            await getUsers();
        }
        fetchUsers().catch(console.error);
    }, [])

    return (
        <div id="user">
            <div className={"d-flex mt-2 pl-4 border-0"}>
                <h1>Users</h1>
            </div>
            <WidgetCard className={'p-2 border-0'} >
                <div className="grid grid-cols-1">
                    {
                        (userData && userData.length) &&
                        <WidgetCard className={'m-3'} >
                            <BootstrapTable
                                fluid
                                bootstrap4
                                keyField='timestamp'
                                columns={columns}
                                data={userData}
                                filter={filterFactory()}
                                filterPosition="top"
                                pagination={paginationFactory()}
                            />
                        </WidgetCard>
                    }
                </div>
            </WidgetCard>
        </div>
    )

}

export default UserDashboard;