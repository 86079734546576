import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DataContext } from './../../context/DataContext';
import { GeneralContext } from '../../context/GeneralContext';
import { Toast } from 'react-bootstrap';
import './theme.css';

const TransferForm = () => {

    const { axios } = useContext(GeneralContext);
    const { user: { address: from }, getUserAssets } = useContext(DataContext);

    const { systemUsers, userAssets } = useContext(DataContext);
    const [activetoSend, setActivetoSend] = useState({ asset: null, qty: 0, to: "" })
    const [toast, setToast] = useState({ title: '', body: '' });
    const [showToast, setShowToast] = useState(false);
    const [maxInputValue, setMaxInputValue] = useState(1)
    const [canSend, setCanSend] = useState(false)

    const transferTokens = async () => {
        try {
            const { data: { error, data } } = await axios.post("/transfer", { data: { from, ...activetoSend, qty: parseInt(activetoSend.qty, 10) } })
            if (error) {
                setToast({ className: 'danger', title: 'Error', body: `Transfer failed !` });
            } else {
                setToast({ className: 'success', title: 'Success', body: `${activetoSend.qty} Tokens transfered successfully !` });
            }
        } catch (error) {
            setToast({ className: 'danger', title: 'Error', body: `Transfer failed !` });
        }
        setShowToast(true);
    };

    useEffect(() => {
        const { asset, qty, to } = activetoSend;
        const send = asset !== null && !!qty && !!to;
        setCanSend(send)
    }, [activetoSend]);

    useEffect(() => {
    }, [canSend, maxInputValue]);


    useEffect(() => {
        if (showToast) {
            setTimeout(() => {
                getUserAssets().catch(console.error);
                setShowToast(false);
            }, 3000);
        }
    }, [showToast]);

    return (
        <>
            <Toast onClick={() => { setShowToast(false) }} show={showToast} className='custom-toast'>
                <Toast.Header closeButton={false} className={`alert-info`}>
                    <strong className="me-auto">{toast.title}</strong>
                </Toast.Header>
                <Toast.Body className="alert-default">{toast.body}</Toast.Body>
            </Toast>
            <div id="col-4">
                <form className="form-inline ps-2 pe-2 w-100">
                    <div className="form-group pe-1">
                        <select
                            onChange={(e) => {
                                if (e.target.value) {
                                    const data = JSON.parse(e.target.value);
                                    setMaxInputValue(data.qty);
                                    setActivetoSend({ ...activetoSend, ...data })
                                }
                            }}
                            id="inputState" className="form-control width-asset">
                            <option value={JSON.stringify({ asset: "", qty: 0 })} selected>Choose Asset</option>
                            {
                                userAssets.map(({ asset, qty }) => {
                                    return (<option value={JSON.stringify({ asset, qty })} >{asset}</option>);
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group pe-1">
                        <input
                            onChange={(e) => {
                                if (e.target.value) {
                                    if (e.target.value <= maxInputValue) {
                                        setActivetoSend({ ...activetoSend, qty: e.target.value });
                                    }
                                }
                            }}
                            type="number"
                            className="form-control width-qty"
                            id="qty"
                            min="1"
                            max={maxInputValue}
                            value={activetoSend.qty}

                        />
                    </div>
                    <div className="form-group pe-1">
                        <select
                            onChange={(e) => {
                                const to = e.target.value;
                                setActivetoSend({ ...activetoSend, to })
                            }}
                            id="inputState" className="form-control width-address ">
                            <option value="" selected>Choose User</option>
                            {
                                systemUsers.map(({ address: to, id }) => {
                                    return (<option value={to} >{id}</option>);
                                })
                            }
                        </select>

                    </div>
                    <Button
                        disabled={!canSend}
                        className={`${!canSend ? "btn-info disabled" : "btn-success"} width-transfer`}
                        onClick={() => {
                            canSend && transferTokens();
                        }}
                    >
                        Transfer
                    </Button>
                </form>
            </div>
        </>
    )

}

export default TransferForm;