import React from 'react';
import WidgetCard from './../../Card/widget-card';
import { useTable } from './hooks/use-table';
import { useColumn } from './hooks/use-column';
import ControlledTable from './controlled-table';

const CoreTable = ({
    title,
    data = [],
    getColumns,
    pageSize = 7,
    setPageSize,
    enablePagination,
    variant = 'modern',
    enableSearch = true,
    paginatorClassName,
    noGutter,
    sticky,
    scroll = { x: 1300 },
    className,
    searchPlaceholder = 'Search...',
}) => {
    const onHeaderCellClick = (value) => ({
        onClick: () => {
            handleSort(value);
        },
    });

    const onDeleteItem = (id) => {
        handleDelete(id);
    };

    const {
        isLoading,
        sortConfig,
        totalItems,
        tableData,
        currentPage,
        searchTerm,
        handleSort,
        handleDelete,
        handleSearch,
        handlePaginate,
        selectedRowKeys,
        handleRowSelect,
        handleSelectAll,
    } = useTable(data, pageSize);

    const columns = React.useMemo(
        () =>
            getColumns({
                data,
                sortConfig,
                onHeaderCellClick,
                onDeleteItem,
                checkedItems: selectedRowKeys,
                onChecked: handleRowSelect,
                handleSelectAll,
            }),
        [
            selectedRowKeys,
            onHeaderCellClick,
            sortConfig.key,
            sortConfig.direction,
            onDeleteItem,
            handleRowSelect,
            handleSelectAll,
        ]
    );

    const { visibleColumns } = useColumn(columns);

    return (
        <WidgetCard
            title={title}
            className={`flex flex-col ${className}`}
            headerClassName="widget-card-header flex-col sm:flex-row [&>.ps-2]:ps-0 [&>.ps-2]:w-full sm:[&>.ps-2]:w-auto [&>.ps-2]:mt-3 sm:[&>.ps-2]:mt-0"
        >
            <div
                className={`table-wrapper flex-grow ${noGutter && '-mx-5 lg:-mx-7'}`}
            >
                <ControlledTable
                    isLoading={isLoading}
                    data={tableData}
                    columns={visibleColumns}
                    scroll={scroll}
                    sticky={sticky}
                    variant={variant}
                    className="mt-4"
                    {...(enablePagination && {
                        paginatorOptions: {
                            pageSize,
                            ...(setPageSize && { setPageSize }),
                            total: totalItems,
                            current: currentPage,
                            onChange: (page) => handlePaginate(page),
                        },
                        paginatorClassName: `mt-4 lg:mt-5 ${noGutter && 'px-5 lg:px-7'} ${paginatorClassName}`,
                    })}
                />
            </div>
        </WidgetCard>
    );
}

export default CoreTable;
