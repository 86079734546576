import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { DataContext } from '../../context/DataContext';
import MapView from './../../components/Map/index';
import { formatAreaCollection } from './../../utils';
import FieldMarkerPopup from './../../components/Map/elements/field-popup'
import MapMarker from './../../components/Map/elements/marker'
import Forecast from './../../components/Chart/forecast';

import './theme.css';

const Dashboard = () => {

    const { axios } = useContext(GeneralContext);
    const { mapDefaultLatitude, mapDefaultLongitude, mapDefaultStyle } = useContext(DataContext);

    const [mapElements, setMapElements] = useState({ markers: [], areas: null });
    const [viewport, setViewport] = useState({ latitude: mapDefaultLatitude, longitude: mapDefaultLongitude, zoom: 7 });
    const [markerDetails, setMarkerDetails] = useState(null);

    const getOilFieldsLocations = async () => {

        const { error: fieldsListingError, data: { data: fieldsData } } = await axios.get(`/field?page=1&items=1000`);
        const uniqueFieldsData = fieldsData.reduce((fields, field) => {
            const { txid, keys, data: { json: { id, tokens } } } = field;
            return { ...fields, [id]: { txid, keys } }
        }, {})
        const { error, data: { data } } = await axios.get(`/location/transactions-by-key/field?page=1&items=1000`);
        const markers = data.map(({ data: { json: { lat, lon, id, area } } }) => {
            const fid = id.split('-').pop();
            const [ownerKey] = uniqueFieldsData[fid].keys.filter(key => key.startsWith('owner-'));
            const txid = uniqueFieldsData[fid].txid;
            const owner = ownerKey ? ownerKey.split('-').pop() : null;
            return {
                area,
                lat,
                lon,
                id,
                type: 'marker',
                icon: "field",
                tokens: 60000000,
                className: "field",
                stream: "field",
                owner,
                txid
            }
        });
        setMapElements({ markers, areas: { "type": "FeatureCollection", features: formatAreaCollection(markers) } })
    }

    useEffect(() => {
        const fetchOilFieldsLocationsData = async () => {
            await getOilFieldsLocations();
        }
        fetchOilFieldsLocationsData().catch(console.error);
    }, [])

    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));

    useEffect(() => {
    }, [mapElements])

    const forecast = [
        {
            key: '2019',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2020',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2021',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2022',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2023',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        }
    ];

    const MarkerList = () => {
        return mapElements.markers ?
            mapElements.markers.map((marker, index) => {
                return (
                    <div key={`markers-list-${index}`}>
                        <FieldMarkerPopup marker={marker} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
                        <MapMarker marker={marker} setViewport={setViewport} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
                    </div>
                );
            }) : <></>;
    }

    return (
        <>
            <MapView
                viewport={viewport}
                setViewport={setViewport}
                mapElements={mapElements}
                mapType={mapDefaultStyle}
            >
                <MarkerList />
            </MapView >
            <div className="pabr text-center">
                <div className='row pt-2 pb-2 pl-4 pr-4'>
                    <div className='col-12 m-2'>
                        <Forecast labels={[{ name: "Developed", color: "#00D1FF" }, { name: "Undeveloped", color: "#6B46FF" }]}  description={"100,000,000,000 Barrels"} title="Total Proven Reserves" data={forecast} className="chart-overlay" key1="Undeveloped" key2="Developed" />
                    </div>
                </div>
            </div>
        </>
    )

}

export default Dashboard;