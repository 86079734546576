import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import moment from "moment";
import './theme.css';

const NFT = ({ stream }) => {

    const { axios } = useContext(GeneralContext);

    const [data, setData] = useState([]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'left',
            },
            title: {
                display: true,
                text: `${stream.charAt(0).toUpperCase() + stream.slice(1)} transaction records`,
            },
        },
    };

    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `${stream.charAt(0).toUpperCase() + stream.slice(1)} transaction records`,
            },
        },
    };

    const labels = data ? data.map(e => {
        const { timereceived: timestamp } = e;
        return moment(timestamp * 1000).format('YYYY-MM-DD hh:mm');
    }).filter((v, i, a) => a.indexOf(v) === i) : [];

    const chartData = {
        labels,
        datasets: [{
            label: stream,
            data: labels.map(e => {
                return data.reduce((a, s) => {
                    const { timereceived: timestamp } = s;
                    return moment(timestamp * 1000).format('YYYY-MM-DD hh:mm') === e ? a + 1 : a;
                }, 0);
            }),
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        }]
    };

    const barChartData = {
        labels,
        datasets: [
            {
                label: 'Transactions',
                data: labels.map(e => {
                    return data.reduce((a, s) => {
                        const { timereceived: timestamp } = s;
                        return moment(timestamp * 1000).format('YYYY-MM-DD hh:mm') === e ? a + 1 : a;
                    }, 0);
                }),
                backgroundColor: `rgba(88, 164, 241, 0.5)`,
            }
        ]
    };

    const getStreamData = async (stream) => {
        try {
            const { data: { data: { assets, assetsTransactions } } } = await axios.get(`/${stream}?page=1&items=1000`);
            const extendedDetails = assets.map(asset => {
                const [extra] = assetsTransactions.filter(trx => (asset.issuetxid === trx.txid));
                return { ...asset, ...extra };
            })
            setData(extendedDetails || null);
        } catch (err) {
            setData(null);
        }
    };

    useEffect(() => {
        const fetchStreamData = async () => {
            await getStreamData(stream);
        }
        fetchStreamData().catch(console.error);
    }, [])

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'assetref',
            text: 'Asset Reference',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'timereceived',
            text: 'Received At',
            formatter: (data, index) => {
                return moment(data * 1000).format('YYYY-MM-DD hh:mm:ss');
            },
            sort: true,
            filter: dateFilter()
        }
    ]

    return (data && data.length) ?
        (

            <div className="d-flex flex-column m-4">
                <div className="row">
                    <div className="col-6 p-4">
                        {stream && <Line options={options} data={chartData} />}
                    </div>
                    <div className="col-6 p-4">
                        {stream && <Bar options={barOptions} data={barChartData} />}
                    </div>
                </div>
                <div>
                    <BootstrapTable
                        fluid
                        bootstrap4
                        keyField='name'
                        columns={columns}
                        data={data}
                        filter={filterFactory()}
                        filterPosition="top"
                        pagination={paginationFactory()}
                    />
                </div>
            </div>
        ) : (
            <div className="no-data p-4 m-4">
                <div className="alert alert-info text-center">
                    No NFT's available
                </div>
            </div>
        )
}

export default NFT;