import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactJsonViewCompare from 'react-json-view-compare';
import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import { useNavigate } from "react-router-dom";

import StatCards from './../../components/Card/stat-cards';
import GoalAccomplished from './../../components/Shared/goal-accomplished';
import WidgetCard from './../../components/Card/widget-card';
import DefaultTable from './../../components/Table/default-table';
import QuickAccess from '../../components/Files/custom/quick-access';
import './theme.css';

import TransactionHistoryContainer from '../../components/Shared/TransactionHistoryContainer';
import HistoryStory from '../../components/HistoryStory';
import HistoryProgress from '../../components/HistoryProgress';

const SingleWellDashboard = () => {

  const { state } = useLocation();
  const navigate = useNavigate();
  const { axios } = useContext(GeneralContext);

  const [data, setData] = useState(null);
  const [documents, setDocuments] = useState([])
  const [wellData, setWellData] = useState(null);
  const [wellEvents, setWellEvents] = useState(null);
  const [eventDocuments, setEventDocuments] = useState(null);
  const [wellId, setWellId] = useState(state ? state.wellId : null);
  const [fieldId, setFieldId] = useState(state ? state.fieldId : null);
  const [fieldOwner, setFieldOwner] = useState(state ? state.owner : null);

  const getWellEvents = async (wellId) => {
    if(!wellId || !fieldId || !fieldOwner) return;

    let formatteduserError = {};
    try {
      const { error: userError, data: { data: userEvents } } = await axios.get(`/event/transactions-by-key/${fieldOwner}?page=1&items=1000`);
      formatteduserError = userEvents.reduce((acc, eventData) => {
        const { txid, timereceived, keys, data: { json: details } } = eventData;
        const { name } = details;
        return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
      }, {})

    } catch (error) {

    }

    let formattedFieldEvent = {};
    try {
      const { error: fieldError, data: { data: fieldEvent } } = await axios.get(`/event/transactions-by-key/${fieldId}?page=1&items=1000`);
      formattedFieldEvent = fieldEvent.reduce((acc, eventData) => {
        const { txid, timereceived, keys, data: { json: details } } = eventData;
        const { name } = details;
        return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
      }, {})
    } catch (error) {

    }

    let formattedEventsData = {};
    try {
      const { error, data: { data: eventsData } } = await axios.get(`/event/transactions-by-key/${wellId}?page=1&items=1000`);
      let imageList = 0;
      formattedEventsData = eventsData.reduce((acc, eventData, index) => {
        const { txid, timereceived, keys, data: { json: details } } = eventData;
        const { name } = details;
        if (acc[name]) {
          imageList = imageList + 1;
          return { ...acc, [`${name} ${imageList}`]: { txid, timereceived, data: { ...details } } };
        } else {
          return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
        }
      }, {})
    } catch (error) {

    }

    let formattedEventDocuments = {};
    try {
      const documentSources = {
        "well": wellId,
        "field": fieldId,
        "user": fieldOwner
      };
      for(const source in documentSources) {
        if(!documentSources[source]) continue;
        const { error, data: { data: documentsData } } = await axios.get(`/${source}-document-metadata/transactions-by-key/${documentSources[source]}?page=1&items=1000`);

        formattedEventDocuments = {
          ...formattedEventDocuments,
          ...documentsData.reduce((acc, documentData) => {
            const { txid, timereceived, keys, data: { json: details } } = documentData;
            const { document_id } = details;
            return { ...acc, [document_id]: { source, txid, timereceived, data: { ...details } } };
          }, {})
        };
      }
      setEventDocuments(formattedEventDocuments);
    } catch (error) {
      // Handle error
    }

    setWellEvents({ ...formatteduserError, ...formattedFieldEvent, ...formattedEventsData, });
  }

  const rn = (max) => (Math.floor(Math.random() * max));

  const chartData = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];

  const chartData1 = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];

  const chartData2 = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];


  const analyticsStatData = [
    {
      id: '1',
      title: 'Sealed Wells',
      metric: '100 / 1,000',
      info: 'Sealed wells out of total wells in the system.',
      increased: true,
      decreased: false,
      // percentage: '+32.40',
      fill: '#015DE1',
      chart: chartData,
    },
    {
      id: '2',
      title: 'Tokens Issued',
      metric: '12,000,000',
      info: 'Total tokens in the system.',
      increased: false,
      decreased: true,
      // percentage: '-4.40',
      fill: '#B92E5D',
      chart: chartData1,
    },
    {
      id: '3',
      title: 'Total Token Value',
      metric: '$270,000,000',
      info: 'Based upon a price of $22.50 per token.',
      increased: true,
      decreased: false,
      // percentage: '+31.40',
      fill: '#c70ca9',
      chart: chartData2,
    },
    {
      id: '4',
      title: 'CO2',
      metric: '44,000,000 MT',
      info: 'Metric Tonnes of CO2 emissions avoided.',
      increased: true,
      decreased: false,
      // percentage: '+32.40',
      fill: '#048848',
      chart: chartData2,
    }
  ];
  const getDocuments = async () => {
    const { error, data: { data } } = await axios.get(`/well-document-metadata/transactions-by-key/${wellId}?page=1&items=10`);
    setDocuments(data);
  }

  const getOilWellsLocations = async () => {
    const { error, data: { data } } = await axios.get(`/location/transactions-by-key/${wellId}?page=1&items=1000`);
    setData(data)
  }

  const getOilWell = async () => {
    const { error, data: { data } } = await axios.get(`/well/transactions-by-key/${wellId}?page=1&items=1000`);
    setWellData(data.length ? data[0] : null);
    if(data.length && data[0]){
      const fieldId = data[0].keys.reverse().find(key => key.startsWith('oil-field-')).split('-').pop();
      if(fieldId) setFieldId(`oil-field-${fieldId}`);
    }
  }

  const getOilField = async () => {
    if(!fieldId) return;
    const { error, data: { data } } = await axios.get(`/field/transactions-by-key/${fieldId}?page=1&items=1000`);
    if(data.length && data[0]){
      const fieldOwner = data[0].keys.reverse().find(key => key.startsWith('owner-')).split('-').pop();
      if(fieldOwner) setFieldOwner(fieldOwner);
    }
  }

  useEffect(() => {
    if (wellId === null) {
      navigate('/');
    } else {
      const fetchOilWell = async () => {
        await getOilWell();
      }
      fetchOilWell().catch(console.error);

      const fetchOilField = async () => {
        await getOilField();
      }
      fetchOilField().catch(console.error);

      const fetchOilWellDocuments = async () => {
        await getDocuments();
      }
      fetchOilWellDocuments().catch(console.error);

      const fetchOilWellsLocationsData = async () => {
        await getOilWellsLocations();
      }
      fetchOilWellsLocationsData().catch(console.error);
    }
  }, [])

  useEffect(() => { }, [wellData, data, documents, fieldId, fieldOwner])

  useEffect(() => {
      const fetchWellEventsData = async () => {
        await getWellEvents(wellId);
      }
      fetchWellEventsData().catch(console.error);
  }, [fieldId, fieldOwner, wellId]);

  useEffect(() => {
        const fetchOilField = async () => {
          await getOilField();
        }
        fetchOilField().catch(console.error);
  }, [fieldId]);

  return (
      <div id="well">
        <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
          <div className="mt-4 grid grid-cols-4 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
            <StatCards data={analyticsStatData}/>
          </div>
        </WidgetCard>
        <div className={"d-flex my-3 px-3 border-0 align-items-center"}>
          <h1>Well: {wellId}</h1>
          {wellData ? <small className={'ml-3'}>{wellData?.txid}</small> : null}
        </div>
        <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3 mt-3'}>
          {documents.length ?
              <div><QuickAccess stream="well" withIcons={true} title="Documents" id={wellId} files={documents}/>
              </div> : <></>}
        </WidgetCard>
        <div className={"d-flex flex-col gap p-3"}>
          <div as="h3" className="text-lg font-semibold xl:text-xl">
            History
          </div>
          {wellEvents && wellData && wellId ?
              <div className={'ml-auto mr-auto'}><HistoryProgress events={wellEvents}/></div> : <>Loading...</>}
        </div>
        <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3 mt-3'}>
          {wellEvents && wellData && wellId ?
              <HistoryStory isField={false} events={wellEvents} data={wellData} fieldId={fieldId} documents={eventDocuments}/> : <></>}
        </WidgetCard>
      </div>
  )

}

export default SingleWellDashboard;