import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { DataContext } from '../../context/DataContext';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Line, Bar } from 'react-chartjs-2';
import { Nav, Dropdown } from 'react-bootstrap';
import moment from "moment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import './theme.css';

const Default = ({ getTransactionsByKey, stream }) => {

    const { axios } = useContext(GeneralContext);
    const { routes } = useContext(DataContext);

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([
        {
            dataField: 'timereceived',
            text: 'Received At',
            sort: true,
            filter: dateFilter()
        },
        {
            dataField: 'valid',
            text: 'Is Valid',
            sort: true,
            formatter: (data, index) => {
                return data ? 'true' : 'false';
            },
        },
        {
            dataField: 'available',
            text: 'Is Available',
            sort: true,
            formatter: (data, index) => {
                return data ? 'true' : 'false';
            },
        },
        {
            dataField: 'keys',
            text: 'Details',
            formatter: (data, index) => {
                return <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        |||
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            (() => data.filter((v, i, a) => a.indexOf(v) === i).map((k, index) => {
                                return <Dropdown.Item key={`details-dropdown-${stream}-${index}-${k}`} onClick={() => { getTransactionsByKey(stream, k, 'transaction') }}>{k} </Dropdown.Item>
                            }))()
                        }
                    </Dropdown.Menu>
                </Dropdown>
            },
        },
        {
            dataField: 'action1',
            text: 'History',
            formatter: (data, row) => {
                const { data: { json: { id } } } = row;
                return <Nav.Link key={`${id}-history`} onClick={(e) => getTransactionsByKey(stream, id, 'history')} eventKey="link-history">History</Nav.Link>
            },
        },
        {
            dataField: 'action2',
            text: 'Files',
            formatter: (data, row) => {
                const { data: { json: { id } } } = row;
                return <Nav.Link key={`${id}-files`} onClick={(e) => getTransactionsByKey(stream, id, 'file')} eventKey="link-files">Files</Nav.Link>
            },
        },
    ]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'left',
            },
            title: {
                display: true,
                text: `${stream.charAt(0).toUpperCase() + stream.slice(1)} transaction records`,
            },
        },
    };

    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `${stream.charAt(0).toUpperCase() + stream.slice(1)} transaction records`,
            },
        },
    };

    const labels = data ? data.map(e => {
        const { timereceived: timestamp } = e;
        return moment(timestamp * 1000).format('YYYY-MM-DD hh:mm');
    }).filter((v, i, a) => a.indexOf(v) === i) : [];

    const chartData = {
        labels,
        datasets: [{
            label: stream,
            data: labels.map(e => {
                return data.reduce((a, s) => {
                    const { timereceived: timestamp } = s;
                    return moment(timestamp * 1000).format('YYYY-MM-DD hh:mm') === e ? a + 1 : a;
                }, 0);
            }),
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        }]
    };

    const barChartData = {
        labels,
        datasets: [
            {
                label: 'Transactions',
                data: labels.map(e => {
                    return data.reduce((a, s) => {
                        const { timereceived: timestamp } = s;
                        return moment(timestamp * 1000).format('YYYY-MM-DD hh:mm') === e ? a + 1 : a;
                    }, 0);
                }),
                backgroundColor: `rgba(88, 164, 241, 0.5)`,
            }
        ]
    };

    const getStreamData = async (stream) => {
        try {
            const { data: { data } } = await axios.get(`/${stream}?page=1&items=1000`);
            setData(data.map(e => ({ ...e, timereceived: moment(e.timereceived * 1000).format('YYYY-MM-DD') })));
        } catch (err) {
            setData(null);
        }
    };

    useEffect(() => {
        const fetchStreamData = async () => {
            await getStreamData(stream);
        }
        fetchStreamData().catch(console.error);
    }, [])

    const locationColumns = [
        {
            dataField: 'data',
            text: 'Trace',
            formatter: (data, index) => {
                const { json: { id } } = data;
                return <Nav.Link key={`${id}-trace`} onClick={(e) => getTransactionsByKey(stream, id, 'maptrace')} eventKey="link-files">Trace</Nav.Link>
            },
        },
        {
            dataField: 'data',
            text: 'Location',
            formatter: (data, index) => {
                const { json: { id } } = data;
                return <Nav.Link key={`${id}-location`} onClick={(e) => getTransactionsByKey(stream, id, 'location')} eventKey="link-files">Location</Nav.Link>
            },
        },

    ]

    const stageColumns = [
        {
            dataField: 'data',
            text: 'Timeline',
            formatter: (data, index) => {
                const { json: { id } } = data;
                return <Nav.Link key={`${id}-timeline`} onClick={(e) => getTransactionsByKey('stage', id, 'timeline')} eventKey="link-timeline">{id}</Nav.Link>
            },
        }
    ]

    return (data && data.length) ?
        (
            <div className="d-flex flex-column m-4">
                <div className="row">
                    <div className="col-6 p-4">
                        {stream && <Line options={options} data={chartData} />}
                    </div>
                    <div className="col-6 p-4">
                        {stream && <Bar options={barOptions} data={barChartData} />}
                    </div>
                </div>
                <div>
                    <BootstrapTable
                        fluid
                        bootstrap4
                        keyField='timestamp'
                        columns={columns}
                        data={data}
                        filter={filterFactory()}
                        filterPosition="top"
                        pagination={paginationFactory()}
                    />
                </div>
            </div>
        ) : (
            <div className="no-data p-4 m-4">
                <div className="alert alert-info text-center">
                    No data available for stream {stream}
                </div>
            </div>
        )
}

export default Default;