import WidgetCard from './../Card/widget-card';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#B92E5D', '#6D1A36', '#D68585', '#FFD1D1'];


const TopTrafficSource = ({ description, title, data, className }) => {

    return (
        <WidgetCard
            title={title}
            titleClassName="font-normal sm:text-sm text-gray-500 mb-2.5 font-inter"
            className={className}
            description={
                <div as="h2" className="me-2 font-semibold">
                    {description}
                </div>
            }
        >
            <ResponsiveContainer className={className} width="100%" height="100%">
                <PieChart className="[&_.recharts-sector:focus]:outline-none">
                    <Pie
                        data={data}
                        innerRadius={60}
                        outerRadius={116}
                        fill="#6D1A36"
                        stroke="rgba(0,0,0,0)"
                        dataKey="value"
                    >
                        {data.map((_, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </WidgetCard>
    );
}

export default TopTrafficSource
