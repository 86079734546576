import { Nav, Navbar } from 'react-bootstrap';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { DataContext } from './../../context/DataContext';
import TransferForm from '../TransferForm';

const Navigation = ({ routes }) => {
    const { user, role, activeRoute, setActiveRoute, handleLogout, totalTokens, systemUsers, userAssets } = useContext(DataContext);
    const navigate = useNavigate();
    const AuthenticationRoutes = () => {
        return !user
            ? <Nav>
                <Nav.Link
                    eventKey={2}
                    href="#"
                    onClick={() => {
                        navigate("/", { state: {} });
                        setActiveRoute('/')
                    }}
                    className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                >
                    Sign in
                </Nav.Link>
            </Nav>
            : <div className="d-flex justify-content-center align-items-center">
                <TransferForm />
                <span className="text-default">
                    <strong> Tokens owned : </strong>
                    <span className="text-info">
                        {totalTokens.toLocaleString()}
                    </span>
                </span>
                <Nav>
                    <Nav.Link
                        eventKey={2}
                        href="#"
                        onClick={async () => {
                            navigate("/login", { state: {} });
                            await handleLogout();
                            setActiveRoute('/login')
                        }}
                        role="button"
                        className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                    >

                        <span className="ml-2">Sign Out</span>
                    </Nav.Link>
                </Nav>
            </div>
    }

    const CustomRoutes = ({ navigation }) => {
        return user
            ? <Nav className="mr-auto">
                {
                    ['admin'].includes(role) &&
                    <Nav.Link
                        href="#"
                        key="navigation-user"
                        onClick={() => {
                            navigate("/user", { state: {} });
                            setActiveRoute('user')
                        }}
                        className={`nav-item ${activeRoute === 'user' ? 'active' : ''}`}
                    >
                        Users
                    </Nav.Link>
                }
                {
                    ['admin', 'user'].includes(role) &&
                    <Nav.Link
                        href="#"
                        key="navigation-field"
                        onClick={() => {
                            navigate("/fields-data", { state: {} });
                            setActiveRoute('field')
                        }}
                        className={`nav-item ${activeRoute === 'field' ? 'active' : ''}`}
                    >
                        Fields
                    </Nav.Link>
                }
                {
                    ['admin', 'user'].includes(role) &&
                    <Nav.Link
                        href="#"
                        key="navigation-well"
                        onClick={() => {
                            navigate("/wells-data", { state: {} });
                            setActiveRoute('well')
                        }}
                        className={`nav-item ${activeRoute === 'well' ? 'active' : ''}`}
                    >
                        Wells
                    </Nav.Link>
                }
            </Nav>
            : <Nav className="mr-auto"></Nav>

    }

    return (
        <>
            <Navbar.Brand href="/">
                <img alt="Logo" src="logo.png" height={30} />
            </Navbar.Brand>
            <CustomRoutes />
            <AuthenticationRoutes />
        </>
    )

}

export default Navigation;