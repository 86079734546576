import { NavLink } from 'react-router-dom';
// import HamburgerButton from '@/layouts/hamburger-button';
import { PiMagnifyingGlassDuotone } from 'react-icons/pi';
// import Sidebar from '@/layouts/hydrogen/sidebar';
import StickyHeader from './helpers/sticky-header';
import Navigation from './../Navigation/index';

export default function Header({ routes }) {
    return (
        <StickyHeader className={'justify-between 2xl:py-5 2xl:pl-6 3xl:px-8'}>
            <Navigation routes={routes} />
        </StickyHeader>
    );
}