import { Badge } from 'react-bootstrap';
import moment from "moment";
import ReactJsonViewCompare from 'react-json-view-compare';

export const getStatusBadge = (status) => {
    switch (status.toLowerCase()) {
        case 'pending':
            return (
                <div className="flex items-center">
                    <Badge color="warning" renderAsDot />
                    <div className="ms-2 font-medium text-orange-dark">{status}</div>
                </div>
            );
        case 'completed':
            return (
                <div className="flex items-center">
                    <Badge color="success" renderAsDot />
                    <div className="ms-2 font-medium text-green-dark">{status}</div>
                </div>
            );
        case 'cancelled':
            return (
                <div className="flex items-center">
                    <Badge color="danger" renderAsDot />
                    <div className="ms-2 font-medium text-red-dark">{status}</div>
                </div>
            );
        default:
            return (
                <div className="flex items-center">
                    <Badge renderAsDot className="bg-gray-400" />
                    <div className="ms-2 font-medium text-gray-600">{status}</div>
                </div>
            );
    }
}

export const getColumns = ({ columns }) => [
    {
        title: "Confirmations",
        dataIndex: 'confirmations',
        key: 'confirmations',
        width: 90,
        render: (value) => <div>{value}</div>,
    },
    {
        title: "Received At",
        dataIndex: 'timereceived',
        key: 'timereceived',
        width: 90,
        render: (value) => <div>{moment(value * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>,
    },
    {
        title: "Is Valid",
        dataIndex: 'valid',
        key: 'valid',
        width: 90,
        render: (value) => <div>{value ? 'true' : 'false'}</div>,
    },
    {
        title: "Is Available",
        dataIndex: 'available',
        key: 'available',
        width: 90,
        render: (value) => <div>{value ? 'true' : 'false'}</div>,
    },
    {
        title: "Id",
        dataIndex: 'data',
        key: 'data',
        width: 90,
        render: (value) => <div>{value.json.id}</div>,
    },
    {
        title: "Transaction",
        dataIndex: 'data',
        key: 'data',
        width: 90,
        render: (value) => <div><pre><ReactJsonViewCompare oldData={value} newData={value} /></pre></div>,
    },
    {
        title: "Keys",
        dataIndex: 'keys',
        key: 'keys',
        width: 90,
        render: (value) => <div><ReactJsonViewCompare oldData={value} newData={value} /></div>,
    }
];

export const getWidgetColumns = ({ columns }) => [
    {
        title: "Received At",
        dataIndex: 'timereceived',
        key: 'timereceived',
        width: 90,
        render: (value) => <div>{moment(value * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>,
    },
    {
        title: "Id",
        dataIndex: 'data',
        key: 'data',
        width: 90,
        render: (value) => <div>{value.json.id}</div>,
    },
    {
        title: "Transaction",
        dataIndex: 'data',
        key: 'data',
        width: 90,
        render: (value) => <div><pre><ReactJsonViewCompare oldData={value} newData={value} /></pre></div>,
    },
    {
        title: "Keys",
        dataIndex: 'keys',
        key: 'keys',
        width: 90,
        render: (value) => <div><ReactJsonViewCompare oldData={value} newData={value} /></div>,
    }

];

