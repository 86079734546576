import {useContext, useEffect, useState} from 'react';
import {GeneralContext} from '../../context/GeneralContext';
import {useLocation} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {dateFilter, textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import WidgetCard from './../../components/Card/widget-card';
import Forecast from './../../components/Chart/forecast';
import DeviceSessions from './../../components/Chart/device-sessions';
import Acquisition from './../../components/Chart/acquisition';

import './theme.css';
import well from "../Well";
import {DataContext} from "../../context/DataContext";
import StatCards from "../../components/Card/stat-cards";

const FieldDashboard = () => {

    const { env } = useContext(GeneralContext);
    const { setActiveRoute } = useContext(DataContext);
    const { axios } = useContext(GeneralContext);

    const { state } = useLocation();
    const navigate = useNavigate();


    const [fieldsLocationData, setFieldsLocationData] = useState(null);
    const [fieldsData, setFieldsData] = useState([]);
    const [wellsData, setWellsData] = useState([]);
    const [assetsData, setAssetsData] = useState([]);
    const [fullFieldsData, setFullFieldsData] = useState([]);

    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));

    const forecast = [
        {
            key: '2019',
            Undeveloped: rnmin(10, 20).toFixed(0),
            Developed: rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2020',
            Undeveloped: rnmin(10, 20).toFixed(0),
            Developed: rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2021',
            Undeveloped: rnmin(10, 20).toFixed(0),
            Developed: rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2022',
            Undeveloped: rnmin(10, 20).toFixed(0),
            Developed: rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2023',
            Undeveloped: rnmin(10, 20).toFixed(0),
            Developed: rnmin(1100, 5000).toFixed(0),
        }
    ];

    const acquisition = [
        {
            day: 'Jan',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Feb',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Mar',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Apr',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'May',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Jun',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Jul',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Aug',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Sep',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Oct',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Nov',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Dec',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
    ];

    const deviceSessions = [
        {
            day: 'Mon',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Tue',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Thu',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Wed',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Fri',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Sun',
            issued: rnmin(100, 1200).toFixed(0),
        },
    ];

    const columns = [
        {
            dataField: 'data',
            text: 'Field',
            sort: true,
            formatter: (data, index) => {
                return <div className={'d-flex align-items-center'}>
                    <img className={'w-20 h-20 border-2 border-gray-200 rounded-circle'}
                        src={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${data.location.lon},${data.location.lat},10,0/120x120?access_token=${env.maps}`}
                         alt={`Satellite image for oil-field-${data?.field.id}`} />
                    <div className={'d-flex flex-column ml-4'}>
                        <Nav.Link className="text-info p-0"
                                  title={`View oil-field-${data?.field.id} details`}
                                  onClick={() => {
                                    navigate(`/field-data`, { state: { [`fieldId`]: `oil-field-${data?.field.id}` } });
                                    setActiveRoute(`field`);
                                  }}>
                            {`oil-field-${data?.field.id}`}
                        </Nav.Link>
                        <small>{data?.field.txid}</small>
                    </div>
                </div>;
            },
        },
        {
            dataField: 'data',
            text: 'Data',
            sort: true,
            formatter: (data, index) => {
                return <div className={'d-flex flex-column gap'}>
                    <span>{data?.field.description ?? null}</span>
                    <span><strong>Wells:</strong> {data?.wells?.length}</span>
                    <span><strong>Tokens:</strong> {data?.assets?.reduce((totalTokens, asset) => totalTokens + asset.issueqty, 0)}</span>
                </div>;
            }
        },
        {
            dataField: 'data.field.timereceived',
            text: 'Created At',
            sort: true,
            filter: dateFilter(),
        },
    ];

    const chartData = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData1 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData2 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const analyticsStatData = [
        {
            id: '1',
            title: 'Sealed Wells',
            metric: '100 / 1,000',
            info: 'Sealed wells of total wells in the system.',
            increased: true,
            decreased: false,
            fill: '#015DE1',
            chart: chartData,
        },
        {
            id: '2',
            title: 'Tokens Issued',
            metric: '12,000,000',
            info: 'Total tokens in the system.',
            increased: false,
            decreased: true,
            fill: '#B92E5D',
            chart: chartData1,
        },
        {
            id: '3',
            title: 'CO2',
            metric: '44,000,000 MT',
            info: 'Metric Tonnes of CO2 emissions avoided.',
            increased: true,
            decreased: false,
            fill: '#048848',
            chart: chartData2,
        }
    ];

    const getOilFieldsLocations = async () => {
        try {
            const { error, data: { data } } = await axios.get(`/location/transactions-by-key/field?page=1&items=1000`);
            if (!error) {
                setFieldsLocationData(data);
            }
        } catch (error) {

        }
    }

    const getOilFields = async () => {
        try {
            const { error, data: { data } } = await axios.get('/field?page=1&items=1000');
            if (!error) {
                const uniqueFieldsData = data.reverse().reduce((fields, field) => {
                    if (!fields.some((f) => f.data.json.id === field.data.json.id)) {
                        fields.push({ ...field, timereceived: moment(field.timereceived * 1000).format('YYYY-MM-DD') });
                    }
                    return fields;
                }, [])
                setFieldsData(uniqueFieldsData);
            }
        } catch (error) {

        }
    }

    const getAssets = async () => {
        try {
            const { error, data: { data } } = await axios.get('/nfts?page=1&items=1000');
            if (!error) {
                const uniqueAssetsData = data.assets.reverse().reduce((assets, asset) => {
                    if (!assets.some((f) => f.name === asset.name)) {
                        assets.push({ ...asset, timereceived: moment(asset.timereceived * 1000).format('YYYY-MM-DD') });
                    }
                    return assets;
                }, [])
                setAssetsData(uniqueAssetsData);
            }
        } catch (error) {

        }
    }

    const getOilWells = async () => {
        try {
            const { error, data: { data } } = await axios.get('/well?page=1&items=1000');
            if (!error) {
                const uniqueWellsData = data.reverse().reduce((wells, well) => {
                    if (!wells.some((f) => f.data.json.id === well.data.json.id)) {
                        wells.push({ ...well, timereceived: moment(well.timereceived * 1000).format('YYYY-MM-DD') });
                    }
                    return wells;
                }, [])
                setWellsData(uniqueWellsData);
            }
        } catch (error) {

        }
    }

    const mergeFieldData = async () => {
        if (fieldsData && fieldsLocationData) {
            const data = fieldsData.map((field) => {
                const {data} = field;
                const location = fieldsLocationData.find(({data: {json: {id: fid}}}) => (fid === `oil-field-${data.json.id}`));
                return {
                    data: {
                        field: {
                            ...data.json,
                            txid: field.txid,
                            timereceived: field.timereceived
                        },
                        wells: (wellsData ? wellsData.filter(({keys}) => (keys.includes(`oil-field-${data.json.id}`))).map((w) => w.data.json) : []),
                        assets: (assetsData ? assetsData.filter(({details: {field_id}}) => (field_id === data.json.id)) : []),
                        location: {
                            lat: location?.data.json?.lat ?? null,
                            lon: location?.data.json?.lon ?? null
                        }
                    }
                };
            });
            setFullFieldsData(data);
        } else {
            setFullFieldsData([]);
        }
    }

    useEffect(() => {
        getOilFieldsLocations().catch(console.error);
        getOilFields().catch(console.error);
        getOilWells().catch(console.error);
        getAssets().catch(console.error);
    }, [])

    useEffect(() => {
        mergeFieldData().catch(console.error);
    }, [fieldsData, fieldsLocationData, wellsData, assetsData])

    useEffect(() => { }, [fieldsData, fieldsLocationData, wellsData, assetsData])

    return (
        <div id="field">
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <StatCards data={analyticsStatData}/>
                </div>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <div className='text-center'>
                        <Forecast
                            labels={[{name: "Developed", color: "#00D1FF"}, {name: "Undeveloped", color: "#6B46FF"}]}
                            description={`100,000,000 Barrels`} title="Total Production Volumes" data={forecast}
                            className="chart-4" key1="Undeveloped" key2="Developed"/>
                    </div>

                    <div className='text-center'>
                        <Acquisition description={`${fieldsData.length.toLocaleString()} Fields`}
                                     title={"Fields Developed v Undeveloped in 2023"} data={acquisition}
                                     className="chart-4"/>
                    </div>
                    <div className='text-center'>
                        <DeviceSessions description={"60,000,000 Tokens"} title="Token Distribution "
                                        data={deviceSessions} className="chart-4"/>
                    </div>
                </div>
            </WidgetCard>
            <div className={"d-flex mt-3 mb-n2 pl-4 border-0"}>
                <h1>Fields</h1>
            </div>
            <WidgetCard className={'mt-2 p-0 border-0'}>
                <div className="grid grid-cols-1">
                    {
                        (fullFieldsData && fullFieldsData.length) ?
                            <WidgetCard className={'m-3'}>
                                <BootstrapTable
                                    fluid
                                    bootstrap4
                                    keyField='timestamp'
                                    columns={columns}
                                    data={fullFieldsData}
                                    filter={filterFactory()}
                                    filterPosition="top"
                                    pagination={paginationFactory()}
                                />
                            </WidgetCard> : <></>
                    }
                </div>
            </WidgetCard>
        </div>
    )
}

export default FieldDashboard;