import WidgetCard from './../Card/widget-card';
import CircleProgressBar from './../../components/Chart/utils/circle-progressbar';

const data = [
  {
    name: 'New Customers',
    value: '30,000',
    percentage: 45,
    color: '#3872FA',
  },
  {
    name: 'Convention Rate',
    value: '75%',
    percentage: 75,
    color: '#f1416c',
  },
  {
    name: 'Page Session',
    value: '67%',
    percentage: 67,
    color: '#7928ca',
  },
];

const GoalAccomplished = ({
  className,
}) => {
  return (
    
    <WidgetCard
      title={'Goal Accomplished'}
      description={'67% acquired this month'}
      rounded="lg"
      descriptionClassName="text-gray-500 mt-1.5"
      className={`mt-3 ml-3 ${className}`}
    >
      <div className="mt-5 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
        {data.map((item) => (
          <div key={item.name} className="grid grid-cols-1 gap-6 text-center">
            <CircleProgressBar
              percentage={item.percentage}
              size={120}
              stroke="#f0f0f0"
              strokeWidth={5}
              progressColor={item.color}
              useParentResponsive={true}
              label={<div as="h6">{item.value}</div>}
              strokeClassName="dark:stroke-gray-200"
            />
            <div className="text-xs font-semibold text-gray-900 @md:text-sm @7xl:text-base dark:text-gray-700">
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </WidgetCard>
  );
}

export default GoalAccomplished;
