import React from 'react';
import Table from './table-ui';
import Spinner from './spinner';

const ControlledTable = ({
    isLoading,
    filterElement,
    filterOptions,
    paginatorOptions,
    tableFooter,
    showLoadingText,
    paginatorClassName,
    className,
    ...tableProps
}) => {
    if (isLoading) {
        return (
            <div className="grid h-full min-h-[128px] flex-grow place-content-center items-center justify-center">
                <Spinner size="xl" />
                {showLoadingText ? (
                    <div as="h6" className="-me-2 mt-4 font-medium text-gray-500">
                        Loading...
                    </div>
                ) : null}
            </div>
        );
    }

    return (
        <>
            {/* {!isEmpty(filterOptions) && (
                <TableFilter {...filterOptions}>{filterElement}</TableFilter>
            )} */}

            <div className="relative">
                <Table
                    scroll={{ x: 1300 }}
                    rowKey={(record) => record.id}
                    className={className}
                    {...tableProps}
                />

                {tableFooter ? tableFooter : null}
            </div>

            {/* {!isEmpty(paginatorOptions) && (
                <TablePagination
                    paginatorClassName={paginatorClassName}
                    {...paginatorOptions}
                />
            )} */}
        </>
    );
}

export default ControlledTable;